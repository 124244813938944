import { computed, ref, set } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { LOCALIZATION_STORE } from '@/store/common/storeConstants';
import { useModuleSettingsStore } from './moduleSettingsStore';
import { fetchTranslationService } from '@/services/application-service/moduleRequests';

export const useLocalizationStore = defineStore(LOCALIZATION_STORE, () => {
    const moduleSettingsStore = useModuleSettingsStore();
    
    /** @type {import('@/store/types/localizationStore').ModuleLanguage} */
    const currentLanguage = ref(null);

    const translations = ref({});

    /** @type {import('@/store/types/localizationStore').TranslationsMeta} */
    const translationsMeta = ref({
        loadedTranslations: {}
    });

    const localizationSettings = computed(() => moduleSettingsStore.moduleSettings?.settings?.localization || null);

    const currentTranslations = computed(() => translations.value[currentLanguage.value.id] || null);

    /**
     * @param {string} applicationId
     * @param {string} moduleId
     * @param {string} languageId
     * @param {import('@/services/application-service/applicationService').IFetchTranslationServiceParams} params
     */
    const loadTranslations = async (params) => {
        try {
            const { application_id, module_id } = moduleSettingsStore.moduleSettings;
            const languageId = currentLanguage.value.id;
            const { loadedTranslations } = translationsMeta.value;
            if (languageId in loadedTranslations && loadedTranslations[languageId]?.includes(params.screenURI) || !languageId) {
                return;
            }
            const { data: { data } } = await fetchTranslationService(application_id, module_id, languageId, params);
            if (data) {
                if (!translations.value[languageId]) {
                    set(translations.value, languageId, {});
                }
                translations.value[languageId] = {
                    ...translations.value[languageId],
                    ...(data.translations || {})
                };
            }
            if (params.scope === 'LOCAL') {
            /** @type {import('@/store/types/localizationStore').ITranslationsMeta} */
                const translationsMetaPayload = {
                    loadedTranslations: {
                        [languageId]: [params.screenURI]
                    }
                };
                updateTranslationsMeta(translationsMetaPayload);
            }
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * @param {import('@/store/types/localizationStore').ITranslationsMeta} meta 
     */
    const updateTranslationsMeta = (meta) => {
        Object.entries(meta.loadedTranslations).forEach(([key, value]) => {
            if (!translationsMeta.value.loadedTranslations[key]) {
                translationsMeta.value.loadedTranslations[key] = [];
            }
            translationsMeta.value.loadedTranslations[key] = [...new Set([
                ...translationsMeta.value.loadedTranslations[key],
                ...value
            ])];
        });
    };

    /**
     * @param {string} langCode 
     */
    const setLanguage = (langCode) => {
        const supportedLanguage = localizationSettings.value?.supportedLanguages.find(lang => lang.queryParam === langCode && !lang.isDisabled);
        const defaultLanguage = localizationSettings.value?.supportedLanguages.find(lang => lang.id === localizationSettings.value?.defaultLanguage);
        currentLanguage.value = {...(supportedLanguage || defaultLanguage || {})};
    };

    /**
     * @param {string} key 
     * @param {string} defaultValue 
     */
    const getTranslation = (key, defaultValue = '') => {
        let translation = defaultValue;
        if (localizationSettings.value?.isEnabled && !currentLanguage.value?.isDisabled && currentTranslations.value) {
            translation = currentTranslations.value[key] ? currentTranslations.value[key] : defaultValue;
        }
        return translation;
    };

    return {
        currentLanguage,
        translations,
        currentTranslations,
        localizationSettings,
        translationsMeta,
        loadTranslations,
        setLanguage,
        getTranslation
    };
});
